import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FaInfoCircle } from "react-icons/fa"
import Trainer from "../components/trainer"
import Title from "../components/title"

// Styles
import styles from "../styles/components/team.module.scss"

const Team = () => {
  const data = useStaticQuery(graphql`
    query {
      prabhash: file(relativePath: { eq: "prabhash.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      almeda: file(relativePath: { eq: "almeda.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ayesh: file(relativePath: { eq: "ayesh.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className={styles.main} id="team">
      <Title text="Meet Our Team" />

      <div className={styles.team}>
        {/* <Trainer
          image={data.avishka.childImageSharp.fluid}
          name="Avishka Senevirathana"
          insta="https://www.instagram.com/lifetime.fitness.by.maduranga/"
          fb="https://www.facebook.com/Lifetime-Fitness-422919781093919/"
        /> */}
        <Trainer
          image={data.prabhash.childImageSharp.fluid}
          name="Ayesh Ranasinghe"
          qualification1="National Diploma in Sports Strength and Conditioning (SLF)"
          qualification2="Certificate for Fitness Trainers (SLF)"
          insta="https://www.instagram.com/lifetime.fitness.by.maduranga/"
          fb="https://www.facebook.com/Lifetime-Fitness-422919781093919/"
        />
        <Trainer
          image={data.almeda.childImageSharp.fluid}
          name="Thumesh Almeda"
          qualification1="Physical Fitness Trainer (NVQ Level 4)"
          qualification2="Physical Fitness Instructor (National Institute Of Sports Science)"
          insta="https://www.instagram.com/lifetime.fitness.by.maduranga/"
          fb="https://www.facebook.com/Lifetime-Fitness-422919781093919/"
        />
        <Trainer
          image={data.ayesh.childImageSharp.fluid}
          name="Dulshan Miyuranga"
          qualification1="Physical Fitness Trainer (NVQ Level 4 - South Asian Academy of Sport Science)"
          insta="https://www.instagram.com/lifetime.fitness.by.maduranga/"
          fb="https://www.facebook.com/Lifetime-Fitness-422919781093919/"
        />
      </div>
      <div className={styles.footer}>
        <FaInfoCircle />
        <p className={styles.text}>
          All our trainers are qualified instructors with a passion for fitness
        </p>
      </div>
    </div>
  )
}

export default Team
